@use 'sass:color';

@import './color';



$testColor: $primary-color;

@function tetrad($color) {
  $hue: hue($color);
  $saturation: saturation($color);
  $lightness: lightness($color);
  $tetrad: [ 
    hsl($hue, $saturation, $lightness),
    hsl($hue + 90 % 360, $saturation, $lightness),
    hsl($hue + 180 % 360, $saturation, $lightness),
    hsl($hue + 270 % 360, $saturation, $lightness), 
  ];
  @return $tetrad;
}
@function multiply ($fore, $back) {
  $red: calc(color.red($back) * red($fore) / 255);
  $green: calc(color.green($back) * green($fore) / 255);
  $blue: calc(color.blue($back) * blue($fore) / 255);
  @return rgb($red, $green, $blue);
}
@function makeColorPalette($color) {
  $light: #fff;
  $dark: multiply($color, $color);
  $tetrad: tetrad($color);
  $palette: (
    50: mix($light, $color, 88%),
    100: mix($light, $color, 70%),
    200: mix($light, $color, 50%),
    300: mix($light, $color, 30%),
    400: mix($light, $color, 15%),
    500: mix($light, $color, 0%),
    600: mix($dark, $color, 13%),
    700: mix($dark, $color, 30%),
    800: mix($dark, $color, 46%),
    900: mix($dark, $color, 75%),
    A100: lighten(saturate(mix($dark, $color, 15%), 80%), 45.6%),
    A200: lighten(saturate(mix($dark, $color, 15%), 80%), 35.6%),
    A400: lighten(saturate(mix($dark, $color, 15%), 100%), 25.6%),
    A700: lighten(saturate(mix($dark, $color, 15%), 100%), 20.5%)
  );
  @return $palette;
}

@each $shade, $color in makeColorPalette($testColor) {
  .primary-#{$shade} {
    background-color: $color;
    animation-name: primary#{$shade};
    &:after {
      content: "#{$color}";
    }
  }
  
  @keyframes primary#{$shade} {
    0% {
      background: $testColor;
    }
    100% {
      background: $color;
    }
  }
}

@each $shade, $color in makeColorPalette($secondary-color) {
    .secondary-#{$shade} {
      background-color: $color;
      animation-name: secondary#{$shade};
      &:after {
        content: "#{$color}";
      }
    }
    
    @keyframes secondary#{$shade} {
      0% {
        background: $testColor;
      }
      100% {
        background: $color;
      }
    }
}

@each $shade, $color in makeColorPalette($tertiary-color) {
.tertiary-#{$shade} {
    background-color: $color;
    animation-name: tertiary#{$shade};
    &:after {
    content: "#{$color}";
    }
}

@keyframes tertiary#{$shade} {
    0% {
    background: $testColor;
    }
    100% {
    background: $color;
    }
}
}