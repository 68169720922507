@use "sass:map";
@import './color';

$primaryDarkerColor: map.get($colors, 'primary', 700);

// Buttons
$buttonActiveBg: $primaryDarkerColor !default;

$buttonBg: $primary-color !default;
$buttonBorder: 2px solid $primary-color !default;


// Secondary
$secondaryButtonBg: $secondary-color !default;
$secondaryButtonBorder: 1px solid $secondary-color !default;
$secondaryButtonTextColor: #ffffff !default;