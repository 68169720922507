@import './variables';

.buttons {
    button {
        margin: 0 0.5em;
        background: $buttonBg;
    }
}

.p-button {
    color: white;
    background: $buttonBg;
    border: $buttonBorder;


    &.p-button-secondary {
        color: $secondaryButtonTextColor;
        background: $secondaryButtonBg;
        border: $secondaryButtonBorder;
    }
}