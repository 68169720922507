@import '../color';

// Overrides styles for pux-customerbar component

.navbar {
    background-color: $secondary-color !important;
}

.pux-portal-bar-button-rounded {
    background-color: $primary-color;
}