$primary-color: #009CD7;
$secondary-color: #0068AA;
$tertiary-color: #B0B7BB;

:root {
    --primary-color: #009CD7;
    --secondary-color: #0068AA;
    --neutral-color: #B0B7BB;
}


$colors: (
    'primary': (50: #e6f5fb,
        100: #b0e0f3,
        200: #8ad1ed,
        300: #54bde4,
        400: #33b0df,
        500: #009cd7,
        600: #008ec4,
        700: #006f99,
        800: #005676,
        900: #00425a,
    ),

    'secondary': (50: #e6f0f7,
        100: #b0d0e5,
        200: #8abad8,
        300: #549ac6,
        400: #3386bb,
        500: #0068aa,
        600: #005f9b,
        700: #004a79,
        800: #00395e,
        900: #002c47,
    ),

    'success': (50: #e8faf0,
        100: #d1f4e0,
        200: #a2e9c1,
        300: #74dfa2,
        400: #45d483,
        500: #17c964,
        600: #12a150,
        700: #0e793c,
        800: #095028,
        900: #095028,
    ),
    'warning': (50: #fefce8,
        100: #fdedd3,
        200: #fbdba7,
        300: #f9c97c,
        400: #f7b750,
        500: #f5a524,
        600: #c4841d,
        700: #936316,
        800: #62420e,
        900: #312107,
    ),
    'danger': (50: #fee7ef,
        100: #fdd0df,
        200: #faa0bf,
        300: #f871a0,
        400: #f54180,
        500: #f31260,
        600: #c20e4d,
        700: #920b3a,
        800: #610726,
        900: #310413,
    ),
    )